<template>
  <div class="veranstaltungen">
    <form class="row g-3">
      <div class="col">
        <main-input-with-label
          name="vorname"
          label="Vorname"
          :value="aktuellePerson.vorname"
          @blur="(event) => (aktuellePerson.vorname = event)"
          required
          v-on:keyup.enter="setFocus('name')"
          autocomplete="given-name"
        >
        </main-input-with-label>

        <main-input-with-label
          id="name"
          name="name"
          label="Name"
          :value="aktuellePerson.Name"
          @blur="(event) => (aktuellePerson.name = event)"
          required
          v-on:keyup.enter="setFocus('floatingSelect')"
          autocomplete="family-name"
        >
        </main-input-with-label>

        <div class="form-floating mb-3">
          <select
            class="form-select"
            :class="{ myInvalid: formselecterror }"
            id="floatingSelect"
            aria-label="Floating label select example"
            v-model="aktuellePerson.gruppe"
            required
            @blur="handleBlur()"
            @change="handleBlur()"
            autocomplete="off"
          >
            <option value="" selected>bitte auswählen</option>
            <template v-for="gruppe in gruppenUebersicht">
              <option
                v-if="gruppe.aktiv"
                :value="gruppe.value"
                :key="gruppe.value"
              >
                {{ gruppe.name }}
              </option>
            </template>
          </select>
          <label for="floatingSelect">Gruppe auswählen:</label>
          <div v-if="formselecterror" class="invalid-message">
            Bitte eine Gruppe auswählen
          </div>
        </div>
      </div>

      <div class="form-check form-switch col-12 mb-3">
        <input
          class="form-check-input m-0"
          type="checkbox"
          id="flexSwitchCheckDefault"
          v-model="aktuellePerson.abmeldung"
          :disabled="
            aktuellePerson.vorname === '' ||
            aktuellePerson.name === '' ||
            aktuellePerson.gruppe === ''
          "
        />
        <label class="form-check-label" for="flexSwitchCheckDefault"
          >Ich muss mich leider abmelden</label
        >
      </div>

      <div v-if="aktuellePerson.abmeldung">
        <div class="form-check mb-3">
          <input
            class="form-check-input m-0"
            :class="{ myInvalid: dsgvocheckerror }"
            type="checkbox"
            v-model="aktuellePerson.dsgvo"
            id="dsgvo"
            required
            @click="handleBlur()"
          />
          <label
            class="form-check-label"
            :class="{ myInvalid: dsgvocheckerror }"
            for="dsgvo"
          >
            DSGVO: Ich bestätige die Verarbeitung meiner eingegebenen Daten.
          </label>
          <div v-if="dsgvocheckerror" class="invalid-message">
            Bitte die Checkbox auswählen
          </div>
        </div>
        <button
          type="submit"
          v-on:click.stop.prevent="aktuellePersonAbmelden()"
          class="btn btn-primary"
        >
          Person abmelden
        </button>
      </div>

      <table class="table table-hover" v-if="!aktuellePerson.abmeldung">
        <thead>
          <tr>
            <!--<th scope="col">#</th>-->
            <th scope="col">
              Datum <br />
              Uhrzeit
            </th>
            <th scope="col-2">Veranstaltung</th>
            <!-- <th scope="col">max_teilnehmer</th> -->
            <th scope="col-2">
              Kosten in Euro<!-- <br />Bbr+Cbr+Partner/Gast // Aktive+Gast // Kind -->
            </th>
            <th scope="col-2">Anmeldung</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="veranstaltung in store.veranstaltungen"
            :key="veranstaltung.id"
          >
            <!--<th scope="row">{{ veranstaltung.id }}</th>-->
            <th scope="row">
              {{ veranstaltung.datum }} <br />
              {{ veranstaltung.uhrzeit }}
            </th>
            <td>
              <div
                class="accordion accordion-flush"
                :id="'accordionFlushExample' + veranstaltung.id"
              >
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    :id="'flush-headingOne' + veranstaltung.id"
                  >
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#flush-collapseOne' + veranstaltung.id"
                      aria-expanded="false"
                      :aria-controls="'flush-collapseOne' + veranstaltung.id"
                    >
                      {{ veranstaltung.name }}
                    </button>
                  </h2>
                  <div
                    :id="'flush-collapseOne' + veranstaltung.id"
                    class="accordion-collapse collapse show"
                    :aria-labelledby="'flush-headingOne' + veranstaltung.id"
                    :data-bs-parent="
                      '#accordionFlushExample' + veranstaltung.id
                    "
                  >
                    <div class="accordion-body">
                      {{ veranstaltung.beschreibung }} <br />
                      {{ veranstaltung.kosten_hinweis }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- <td>{{ veranstaltung.max_teilnehmer }}</td> -->
            <td>
              {{ volleKosten ? veranstaltung.kosten_voll : "" }}
              {{ halbeKosten ? veranstaltung.kosten_halb : "" }}
              <!--// {{ veranstaltung.kosten_wenig }} -->
            </td>
            <td>
              <main-radio
                v-if="erlaubteAnmeldung(veranstaltung, aktuellePerson)"
                :key="aktuellePerson.id"
                :value="
                  aktuellePerson.anmeldungen.some((a) => a === veranstaltung.id)
                "
                @auswahl="
                  ($event) => saveAnmeldung(Number(veranstaltung.id), $event)
                "
                :id="Number(veranstaltung.id)"
                name="anmeldung"
                required
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div v-show="isValidationError" class="alert alert-warning" role="alert">
        <strong>Fehler in Eingabe bitte überprüfen!</strong>
        <p v-if="aktuellePerson.vorname.length < 3">
          Der Vorname sollte mehr als 3 Buchstaben haben
        </p>
        <p v-if="aktuellePerson.name.length < 3">
          Der Name sollte mehr als 3 Buchstaben haben
        </p>
        <p v-if="aktuellePerson.gruppe === ''">
          Eine Gruppe sollte ausgewählt sein
        </p>
        <p v-if="aktuellePerson.anmeldungen.length < 1">
          Mindestens 1 Veranstaltung muss ausgewählt sein
        </p>
      </div>

      <button
        type="submit"
        v-if="!aktuellePerson.abmeldung"
        v-on:click.stop.prevent="aktuellePersonDemStoreHinzufuegen()"
        class="btn btn-primary"
      >
        Person hinzufügen
      </button>
    </form>
  </div>
</template>

<script>
import MainRadio from "./MainRadio.vue";
import MainInputWithLabel from "./MainInputWithLabel.vue";
import { useCartStore } from "@/store/cart";
import { mapActions } from "pinia";

export default {
  name: "MainVeranstaltung",
  components: {
    MainRadio,
    MainInputWithLabel,
  },
  data() {
    return {
      aktuellePerson: {
        id: Math.random(),
        vorname: "",
        name: "",
        gruppe: "",
        kosten: 0.0,
        anmeldungen: [],
        abmeldung: false,
        dsgvo: false,
      },
      showValidationError: false,
      formselecterror: false,
      dsgvocheckerror: false,
      gruppenUebersicht: [
        {
          value: "AKTIVE", // Wert für die DB
          name: "Aktive", // Wert für die Anzeige im Dropdown
          aktiv: true, // Option steht in der Anzeige zur Verfügung
          typ: "MITGLIED", // definiert ob diese Gruppe sich anmelden darf (MITGLIED/ GAST /KIND) -> ()
        },
        {
          value: "BUNDESBRUDER",
          name: "Bundesbruder",
          aktiv: true,
          typ: "MITGLIED",
        },
        {
          value: "CARTELL",
          name: "Cartellbruder",
          aktiv: false,
          typ: "GAST",
        },
        {
          value: "GAST",
          name: "Gast",
          aktiv: true,
          typ: "GAST",
        },
        {
          value: "GAST_ERM",
          name: "Gast (ermäßigt)",
          aktiv: false,
          typ: "GAST",
        },
        {
          value: "KIND",
          name: "Kind",
          aktiv: false,
          typ: "KIND",
        },
        {
          value: "KORPORIERT",
          name: "Korporiert",
          aktiv: true,
          typ: "GAST",
        },
        {
          value: "KORPORIERT_ERM",
          name: "Korporiert (ermäßigt)",
          aktiv: false,
          typ: "GAST",
        },
        {
          value: "PARTNER",
          name: "Partner/Gast/Dame eines Bundesbruders (AH oder Cbr)",
          aktiv: false,
          typ: "GAST",
        },
      ],
      // lege Kostengruppen fest; zuordnen was für die Nächste Veranstaltung gewünscht ist.
      vollekosten: ["BUNDESBRUDER", "KORPORIERT", "GAST"],
      halbekosten: ["AKTIVE"],
      keinekosten: [],
    };
  },
  beforeMount() {
    if (!process.env.VUE_APP_LOCAL_DEVELOPMENT) {
    fetch("/getveranstaltungen.php")
      .then((response) => response.json())
      .then((result) => this.addVeranstaltungen([...Object.values(result)]));
    } else {
      // Offlinedaten zum Testen- ein kommentieren wenn man diese verfügbar haben möchte
      this.addVeranstaltungen([...Object.values({"16":{"id":"16","gruppe":"203","name":"Tanzball","max_teilnehmer":"150","kosten_voll":"35","kosten_halb":"25","kosten_wenig":null,"kosten_hinweis":"","gaeste_erlaubt":"1","kinder_erlaubt":"0","anmeldung_erlaubt":"1","prio":"0","beschreibung":"Der Tanzball findet in der Tanzschule B\u00e4ulke in Darmstadt statt und wird mit Livemusik begleitet.","erstellt":"2022-07-15 18:47:38","datum":"2022-11-19","uhrzeit":"19:00:00"}})]);
    }
  },
  setup() {
    const store = useCartStore();
    const { addPerson, addVeranstaltungen } = mapActions(useCartStore, [
      "addPerson",
      "addVeranstaltungen",
    ]);
    return { store, addPerson, addVeranstaltungen };
  },
  computed: {
    isValidationError() {
      return !!this.showValidationError;
    },
    volleKosten() {
      return this.vollekosten.includes(this.aktuellePerson.gruppe);
    },
    halbeKosten() {
      return this.halbekosten.includes(this.aktuellePerson.gruppe);
    },
    getAnmeldungen() {
      if (this.aktuellePerson.anmeldungen.length > 0) {
        return this.aktuellePerson.anmeldungen;
      } else {
        return [];
      }
    },
    inkorrekteEingabe() {
      if (
        this.aktuellePerson.vorname.length < 3 ||
        this.aktuellePerson.name.length < 3 ||
        this.aktuellePerson.gruppe.length < 3 ||
        this.aktuellePerson.anmeldungen.length < 1
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setFocus: function (element) {
      document.getElementById(element).focus();
    },
    handleBlur() {
      this.formselecterror = false;
      if (this.aktuellePerson.gruppe === "") {
        // console.log("lol")
        this.formselecterror = true;
      }
      this.showValidationError = false;
    },
    teilnahme(anmeldungen, veranstaltung_id) {
      return anmeldungen.includes(veranstaltung_id);
    },
    erlaubteAnmeldung(veranstaltung, aktuellePerson) {
      const aktiveGruppen = this.gruppenUebersicht.filter((item) => item.aktiv);
      const gruppeKind = veranstaltung.kinder_erlaubt
        ? aktiveGruppen.filter((item) => item.typ === "KIND")
        : [];
      const gruppeGast = veranstaltung.gaeste_erlaubt
        ? aktiveGruppen.filter((item) => item.typ === "GAST")
        : [];
      const gruppeMitglied = aktiveGruppen.filter(
        (item) => item.typ === "MITGLIED"
      );
      const erlaubteGruppe = gruppeMitglied
        .concat(gruppeKind, gruppeGast)
        .map((g) => g.value);
      return (
        Number(veranstaltung.anmeldung_erlaubt) &&
        erlaubteGruppe.includes(aktuellePerson.gruppe)
      );
    },
    saveAnmeldung(veranstaltung_id, radioinput) {
      this.handleBlur();
      if (radioinput) {
        if (!this.aktuellePerson.anmeldungen.includes(veranstaltung_id)) {
          this.aktuellePerson.anmeldungen = [
            ...this.aktuellePerson.anmeldungen,
            veranstaltung_id,
          ];
        }
      } else {
        this.aktuellePerson.anmeldungen =
          this.aktuellePerson.anmeldungen.filter((a) => a != veranstaltung_id);
      }
    },
    bestimmeKosten() {
      const veranstaltungskosten = this.aktuellePerson.anmeldungen
        .map((vid) => {
          return Object.values(this.store.veranstaltungen)
            .filter((v) => {
              return Number(v.id) === vid;
            })
            .map((v) => {
              const gruppe = this.aktuellePerson.gruppe;
              if (this.vollekosten.includes(gruppe)) {
                return Number(v.kosten_voll);
              }
              if (this.halbekosten.includes(gruppe)) {
                return Number(v.kosten_halb);
              }
              if (this.keinekosten.includes(gruppe)) {
                return Number(v.kosten_wenig);
              }
              return 0;
            });
        })
        .reduce((acc, kosten) => {
          return Number(acc) + Number(kosten);
        }, 0.0);
      return veranstaltungskosten !== [] ? veranstaltungskosten : 0;
    },
    aktuellePersonDemStoreHinzufuegen() {
      if (this.inkorrekteEingabe) {
        // this.validateForm;
        console.log("inkorrekte Eingabe");
        this.showValidationError = true;
        return;
      }
      this.showValidationError = false;
      console.log("korrekte Eingabe");
      this.aktuellePerson.kosten = this.bestimmeKosten();
      this.addPerson(Object.assign({}, this.aktuellePerson));
      this.resetAktuellePerson();
      this.$emit("ausblenden");
    },
    aktuellePersonAbmelden() {
      if (this.aktuellePerson.abmeldung) {
        this.dsgvocheckerror = false;
        if (!this.aktuellePerson.dsgvo) {
          this.dsgvocheckerror = true;
          return;
        }
        // Creating a XHR object
        let xhr = new XMLHttpRequest();
        let url = "/abmelden.php";

        // open a connection
        xhr.open("POST", url, true);

        // Set the request header i.e. which type of content you are sending
        xhr.setRequestHeader("Content-Type", "application/json");

        // Create a state change callback
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // Print received data from server
          }
        };

        // Converting JSON data to string
        var data = JSON.stringify({
          formData: this.aktuellePerson,
        });

        // Sending data with the request
        xhr.send(data);

        this.$emit("abmeldung-gesendet", this.aktuellePerson);
        // console.log('Abmeldung', this.aktuellePerson);
        this.resetAktuellePerson();
      } else {
        // console.log('keine Abmeldung möglich', this.aktuellePerson);
      }
    },
    resetAktuellePerson() {
      this.aktuellePerson = {
        id: Math.random(),
        vorname: "",
        name: "",
        gruppe: "",
        anmeldungen: [],
      };
    },
  },
};
</script>

<style scoped>
.accordion-button:not(.collapsed) {
  color: #3e7edd;
  background-color: #eef5fd;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.invalid-message {
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.95em;
  color: #dc3545;
}

.myInvalid {
  border-color: #dc3545;
  color: #dc3545;
}
.myInvalid:focus {
  box-shadow: 0 0 0.5rem rgb(220 53 69 / 25%);
}
</style>
