<template>
  <div class="container-lg">
    <div class="row">
      <h1>
        {{appTitel}}
      </h1>
      <main-card
        name="Anleitung"
        v-show="!formularAbgesendet && !abmeldung"
        header="Anleitung"
        title="Wie funktioniert die Anmeldung:"
      >
        <main-anleitung v-show="zeigeAnleitung"></main-anleitung>
        <button
          v-if="zeigeAnleitung"
          type="button"
          class="btn btn-primary text-center"
          @click="zeigeAnleitung = false"
        >
          Anleitung ausblenden
        </button>
        <button
          v-if="!zeigeAnleitung"
          type="button"
          class="btn btn-primary"
          @click="zeigeAnleitung = true"
        >
          Anleitung einblenden
        </button>
      </main-card>

      <main-card
        v-if="!formularAbgesendet && !abmeldung"
        header="Schritt 1"
        id="schritt1"
      >
        <p>Die Auswahl ist pro teilnehmender Person zu treffen</p>
        <p v-if="store.personen.length > 0">
          Wenn alle Personen eingetragen sind gehe zu
          <a href="#schritt2">Schritt 2</a>
        </p>
        <main-veranstaltung
          :key="Math.random()"
          v-if="zeigePersonenEingabe"
          @ausblenden="
            zeigePersonenEingabe = false;
            zeigeAnleitung = false;
            setFocusToSchritt1();
          "
          @abmeldung-gesendet="($event) => setFocusToAbmeldung($event)"
        />

        <button
          v-if="!zeigePersonenEingabe"
          type="button"
          class="btn btn-primary"
          @click="zeigePersonenEingabe = true"
        >
          weitere Person hinzufügen
        </button>
      </main-card>

      <main-card
        v-if="!formularAbgesendet && store.personen.length > 0"
        header="Schritt 2"
        id="schritt2"
      >
        <main-cart :key="Math.random()" />
      </main-card>

      <main-card
        v-if="!formularAbgesendet && store.personen.length > 0"
        header="Schritt 3"
        id="schritt3"
      >
        <main-adressdaten
          :key="Math.random()"
          @formular-abgesendet="(event) => (formularAbgesendet = event)"
        />
      </main-card>

      <main-card v-show="formularAbgesendet" header="Schritt 4" id="schritt4">
        <div class="alert alert-success" role="alert">
          Anmeldung abgeschickt!
        </div>
        <p>
          Überprüfe bitte dein Postfach. Innerhalb der nächsten 30 Minuten
          sollte eine Bestätigungs Email zur Anmeldung ankommen.
        </p>
        <p>Weitere Details sind darin beschrieben.</p>
      </main-card>

      <main-card v-show="formularAbgesendet" header="Schritt 5" id="schritt5">
        <div class="alert alert-info" role="alert">
          Ist deine Email angekommen, dann überweise bitte den genannten Betrag
          auf unser Konto: <br />
          <strong>
            Aktivenkasse der KDStV Rheinpfalz – IBAN: DE27 5085 0150 0000 7497
            61</strong
          >
        </div>
        <p>Wir wünschen einen schönen Tanzball!</p>
        <!--<p>Vivat Crescat Floreat Rheinpfalz! ad multos annos.</p>-->
      </main-card>

      <main-card v-show="abmeldung" header="Abmeldung" id="abmeldung">
        <p>
          Liebe(r) <strong>{{ abmelder.vorname }} {{ abmelder.name }}</strong
          >, danke für deine Rückmeldung!
        </p>
        <p>
          Schade das du es nicht schaffst, hoffentlich sehen wir uns bei der
          nächsten Veranstaltung!
        </p>
        <p>Viele Grüße vom <strong>Aktiven-XX</strong></p>
      </main-card>
    </div>
  </div>
</template>

<script>
import MainVeranstaltung from "./components/MainVeranstaltung.vue";
import MainAdressdaten from "./components/MainAdressdaten.vue";
import MainAnleitung from "./components/MainAnleitung.vue";
import MainCart from "./components/MainCart.vue";
import MainCard from "./components/MainCard.vue";

import { useCartStore } from "@/store/cart";

export default {
  name: "App",
  components: {
    MainVeranstaltung,
    MainAdressdaten,
    MainAnleitung,
    MainCart,
    MainCard,
  },
  setup() {
    const store = useCartStore();
    return { store };
  },
  data() {
    return {
      veranstaltung: "",
      formularAbgesendet: false,
      zeigeAnleitung: true,
      zeigePersonenEingabe: true,
      abmeldung: false,
      abmelder: [],
    };
  },
  methods: {
    async setFocusToSchritt1() {
      await this.$nextTick();
      var element = document.getElementById("schritt1");
      element.focus();
      element.scrollIntoView();
    },
    async setFocusToAbmeldung(event) {
      this.abmeldung = true;
      this.abmelder = event;
      // console.log('abmeldung gesendet', event);
      await this.$nextTick();
      var element = document.getElementById("abmeldung");
      element.focus();
      element.scrollIntoView();
    },
  },
  computed: {
    semesterId() {
      return this.store.veranstaltungen.map((v) => v.gruppe)[0];
    },
    appTitel() {
      return process.env.VUE_APP_NAME;
    }
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
