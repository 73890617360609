<template>
  <div class="cart" v-if="store.personen.length > 0">
    <h2>Zusammenfassung:</h2>
    <p>Hier seht ihr die bisher eingetragenen Personen.</p>
    <p>Wünscht ihr eine weitere Person einzutragen gehe zu <a href="#schritt1">Schritt 1</a></p>
    <p>
      Stimmt etwas nicht, bitte die Person entfernen und bitte oben bei <a href="#schritt1">Schritt 1</a> noch einmal
      eintragen.
    </p>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col-2">Vorname</th>
          <th scope="col">Nachname</th>
          <th scope="col">Gruppe</th>
          <th scope="col">Kosten</th>
          <th scope="col-2">Veranstaltung #</th>
          <th scope="col">Optionen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(person, index) in store.personen" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ person.vorname }}</td>
          <td>{{ person.name }}</td>
          <td>{{ person.gruppe }}</td>
          <td>{{ person.kosten }} €</td>
          <td>
            <p v-for="(v_name, index2) in getVeranstaltungstitelFuerAnmeldungen(person)" :key="v_name + index + index2">
              {{ v_name }}
            </p>
          </td>
          <td>
            <div class="btn btn-secondary" @click="entferne(person.id)">entfernen</div>
          </td>
        </tr>
      </tbody>
    </table>

    <p>Sind alle Angaben wie gewünscht gehe zu <a href="#schritt3">Schritt 3</a></p>
  </div>
</template>

<script>
import { useCartStore } from "@/store/cart";
import { mapActions } from "pinia";

export default {
  name: "MainCart",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
    };
  },
  setup() {
    const store = useCartStore();
    const { addPerson } = mapActions(useCartStore, ["addPerson"]);
    return { store, addPerson };
  },
  computed: {},
  methods: {
    getVeranstaltungstitelFuerAnmeldungen(person) {
      const titelListe = person.anmeldungen
        .map((vid) =>
          this.store.veranstaltungen
            .filter((v) => Number(v.id) === vid)
            .map((v) => {
              return v.name;
            })
        )
        .flat();
      return titelListe;
    },
    entferne(id) {
      this.store.personen = this.store.personen.filter((p) => p.id !== id);
    },
  },
};
</script>

<style scoped></style>
