<template>
    <div>
        <p>Bitte befolgt die hier aufgeführten Schritte:</p>
        <div class="text-start offset-lg-2 offset-xl-4">
            <p>
            <strong>Schritt 1:</strong> Personen/Teilnehmer <b>einzeln</b> für
            Veranstaltung eintragen und hinzufügen.
            </p>
            <p><strong>Schritt 2:</strong> Anmeldungen überprüfen.</p>
            <p>
            <strong>Schritt 3:</strong> Adressdaten erfassen und Anmeldung
            abschicken.
            </p>
            <p><strong>Schritt 4:</strong> Anmelde-E-Mail wurde empfangen.</p>
            <p>
            <strong>Schritt 5:</strong> Genannten Betrag an das Konto
            überweisen.
            </p>
            <p>
            <strong>Erst ab hier seid ihr angemeldet!</strong>
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: "MainAnleitung",
  components: {},
    props: {
    zeigeAnleitung: Boolean,
    },
}
</script>


<style>

</style>