import { defineStore } from "pinia";

export const useCartStore = defineStore("cart", {
  state: () => {
    return { personen: [], veranstaltungen: [] };
  },
  getters: {},
  actions: {
    reset() {
      this.personen = [];
    },
    addPerson(newPerson) {
      this.personen = [...this.personen, newPerson];
    },
    addVeranstaltungen(newVeranstaltungen) {
      this.veranstaltungen = newVeranstaltungen;
    },
  },
});
