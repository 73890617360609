<template>
  <div class="col-12">
    <h1>Adressdatenerfassung</h1>
    <p>
      Sind alle Personen erfasst muss hier die anmeldende Person hinterlegt
      werden:
    </p>
    <form>
      <div class="form-floating mb-3">
        <select
          class="form-select"
          id="floatingSelect"
          aria-label="Floating label select example"
          @change="onChange($event)"
        >
          <option value="">bitte wählen</option>
          <option
            v-for="(aPerson, index) in angemeldetePersonen"
            :key="index"
            :value="aPerson.id"
          >
            {{ index + 1 }}-{{ aPerson.vorname }}-{{ aPerson.name }}
          </option>
        </select>
        <label for="floatingSelect" class="form-label"
          >Vorauswahl einer eingetragenen Person (Optional)</label
        >
      </div>

      <main-input-with-label
        id="vorname"
        name="vorname"
        label="Vorname"
        :key="formData.vorname"
        :value="formData.vorname"
        @blur="(event) => (formData.vorname = event)"
        required
        v-on:keyup.enter="setFocus('name')"
        autocomplete="given-name"
      >
      </main-input-with-label>

      <main-input-with-label
        id="name"
        name="name"
        label="Name"
        :key="formData.name"
        :value="formData.name"
        @blur="(event) => (formData.name = event)"
        required
        v-on:keyup.enter="setFocus('email')"
        autocomplete="family-name"
      >
      </main-input-with-label>

      <main-input-with-label
        id="email"
        name="email"
        label="Email"
        type="email"
        :validationWesternText="false"
        validationEmail
        :value="formData.email"
        @blur="(event) => (formData.email = event)"
        required
        v-on:keyup.enter="setFocus('telefon')"
        autocomplete="email"
      >
      </main-input-with-label>

      <main-input-with-label
        id="telefon"
        name="telefon"
        label="Telefonnummer"
        type="tel"
        :validationMinLength="5"
        :validationMaxLength="32"
        validationNumber
        :validationWesternText="false"
        :value="formData.telefon"
        @blur="(event) => (formData.telefon = event)"
        required
        v-on:keyup.enter="setFocus('dsgvo')"
        autocomplete="tel"
      >
      </main-input-with-label>

      <div class="form-floating mb-3">
        <input
          type="number"
          class="form-control"
          id="gesamtkosten"
          v-model="berechneGesamtkosten"
          disabled
        />
        <label for="gesamtkosten" class="form-label">Gesamtkosten</label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          :class="{ myInvalid: formcheckerror }"
          type="checkbox"
          v-model="formData.dsgvo"
          id="dsgvo"
          required
          @click="handleBlur()"
        />
        <label class="form-check-label" 
          :class="{ myInvalid: formcheckerror }" for="dsgvo">
          DSGVO: Ich bestätige die Verarbeitung meiner eingegebenen Daten.
        </label>
        <div v-if="formcheckerror" class="invalid-message">
          Bitte die Checkbox auswählen
        </div>
      </div>

      <div
        v-show="showValidationError"
        class="alert alert-warning"
        role="alert"
      >
        <strong>Fehler in Eingabe bitte überprüfen!</strong>
        <p v-if="formData.vorname.length < 3">
          Der Vorname sollte mehr als 3 Buchstaben haben
        </p>
        <p v-if="formData.name.length < 3">
          Der Name sollte mehr als 3 Buchstaben haben
        </p>
        <p v-if="formData.email.length < 9">
          Die Email sollte mehr als 9 Buchstaben haben
        </p>
        <p v-if="formData.email.length > 9 && !validateEmail(formData.email)">
          Bitte eine korrekte Email eintragen
        </p>
        <p v-if="formData.telefon < 9">
          Die Telefonnummer sollte mehr als 9 Ziffern haben
        </p>
        <p v-if="!formData.dsgvo">Der Verarbeitung muss zugestimmt werden</p>
      </div>
      <button type="button" class="btn btn-primary" @click="formularAbsenden()">
        Anmeldung abschicken
      </button>
    </form>
  </div>
</template>

<script>
import { useCartStore } from "@/store/cart";
import { mapActions } from "pinia";
import MainInputWithLabel from "./MainInputWithLabel.vue";

export default {
  name: "MainAdressdaten",
  components: {
    MainInputWithLabel,
  },
  setup() {
    const store = useCartStore();
    const { reset } = mapActions(useCartStore, ["reset"]);
    return { store, reset };
  },
  data() {
    return {
      formData: {
        vorname: "",
        name: "",
        email: "",
        telefon: "",
        dsgvo: false,
        gesamtkosten: 0.0,
      },
      showValidationError: false,
      formcheckerror: false,
    };
  },
  computed: {
    disabledButton() {
      return (
        this.formData.vorname.length <= 2 ||
        this.formData.name.length <= 2 ||
        this.formData.email.length <= 8 ||
        this.formData.telefon.length <= 8 ||
        !this.formData.dsgvo ||
        !this.validateEmail(this.formData.email)
      );
    },
    berechneGesamtkosten() {
      return this.store.personen
        .map((p) => p.kosten)
        .reduce((acc, kosten) => acc + kosten, 0);
    },
    angemeldetePersonen() {
      return this.store.personen;
    },
  },
  methods: {
    handleBlur() {
      this.showValidationError = false;
      this.formcheckerror = false;
      if (this.formData.dsgvo) {
        this.formcheckerror = true;
        return;
      }
    },
    validateEmail(email) {
      var re = new RegExp(
        "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$"
      );
      return re.test(email);
    },
    onChange(event) {
      if (event.target.value === "") {
        this.formData.vorname = "";
        this.formData.name = "";
      }
      let tempId = event.target.value;
      let tempPerson = this.store.personen.find(
        (pers) => pers.id === Number(tempId)
      );
      if (event.target.value !== "" || tempPerson !== undefined) {
        this.formData.vorname = tempPerson.vorname;
        this.formData.name = tempPerson.name;
      }
    },
    formularAbsenden() {
      if (this.disabledButton) {
        this.showValidationError = true;
        return;
      }
      this.formcheckerror = false;
      if (!this.formData.dsgvo) {
        this.formcheckerror = true;
        return;
      }

      this.formData.gesamtkosten = this.store.personen
        .map((p) => p.kosten)
        .reduce((acc, kosten) => acc + kosten, 0);

      // Creating a XHR object
      let xhr = new XMLHttpRequest();
      let url = "/anmelden.php";

      // open a connection
      xhr.open("POST", url, true);

      // Set the request header i.e. which type of content you are sending
      xhr.setRequestHeader("Content-Type", "application/json");

      // Create a state change callback
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // Print received data from server
        }
      };

      // Converting JSON data to string
      var data = JSON.stringify({
        formData: this.formData,
        personen: this.store.personen,
      });

      
      if (!process.env.VUE_APP_LOCAL_DEVELOPMENT) {
        // Sending data with the request
        xhr.send(data);
      } else {
        alert(data);
      }

      this.reset();
      this.$emit("formular-abgesendet", true);
    },
  },
};
</script>

<style scoped>
.invalid-message {
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.95em;
  color: #dc3545;
}

.myInvalid {
  border-color: #dc3545;
  color: #dc3545;
}
.myInvalid:focus {
  box-shadow: 0 0 0.5rem rgb(220 53 69 / 25%);
}
</style>
