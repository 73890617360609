 <template>
  <div class="form-floating mb-3">
    <input
      :type="type"
      class="form-control"
      :class="{ myInvalid: error }"
      :id="name"
      :placeholder="placeholder"
      :minlength="validationMinLength"
      :maxlength="validationMaxLength"
      :required="required"
      v-model="dataValue"
      @blur="handleBlur()"
      :autocomplete="autocomplete"
    />
    <label :for="name" class="form-label">{{ label }}</label>
    <div v-if="error" class="invalid-message">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataValue: this.value,
      error: false,
    };
  },
  props: {
    value: String,
    name: String,
    label: String,
    autocomplete: String,
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: " ",
    },
    type: {
      type: String,
      default: "text",
    },
    validationMinLength: {
      type: Number,
      default: 3,
    },
    validationMaxLength: {
      type: Number,
      default: 50,
    },
    validationWesternText: {
      type: Boolean,
      default: true,
    },
    validationEmail: {
      type: Boolean,
      default: false,
    },
    validationNumber: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Überprüfe die Eingabe!",
    },
  },
  methods: {
    handleBlur() {
      this.error = false;
      if (
        this.required &&
        (this.dataValue === null ||this.dataValue === ""||this.dataValue === undefined || this.dataValue.length === 0)
      ) {
        // console.log("required");
        this.error = true;
        return;
      }

      // validate length
      if (this.dataValue.length < this.validationMinLength) {
        // console.log("too short");
        this.error = true;
        return;
      }

      // validate length
      if (this.dataValue.length > this.validationMaxLength) {
        // console.log("too long");
        this.error = true;
        return;
      }

      // validate String
      const westernText = new RegExp("^[A-Za-zÄÖÜäöüßéèê ]+$");
      if (this.validationWesternText && !westernText.test(this.dataValue)) {
        // console.log("falsche Zeichen");
        this.error = true;
        return;
      }

      // validate Email
      const emailChars = new RegExp(
        "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$"
      );
      if (this.validationEmail && !emailChars.test(this.dataValue)) {
        // console.log("falsche Email Zeichen");
        this.error = true;
        return;
      }
      // validate Email
      const numberChars = new RegExp("^([0-9 /-]+)$");
      if (this.validationNumber && !numberChars.test(this.dataValue)) {
        // console.log("falsche  Zeichen Telefon ");
        this.error = true;
        return;
      }

      // console.log("handleBlur", this.dataValue);
      this.$emit("blur", this.dataValue);
    },
  },
};
</script>

<style scoped>
.myInvalid {
  border-color: #dc3545;
}
.myInvalid:focus {
  box-shadow: 0 0 0.5rem rgb(220 53 69 / 25%);
}
.invalid-message {
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.95em;
  color: #dc3545;
}
</style>
