<template>
  <div class="MainRadio">
    <form>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          :name="'inlineRadioOptions' + name + id"
          :id="'inlineRadio1' + name + id"
          :key="id + '_no'"
          :value="!value"
          :checked="value === false"
          @click="$emit('auswahl', false)"
        />
        <label class="form-check-label" :for="'inlineRadio1' + name + id"
          >nein</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          :name="'inlineRadioOptions' + name + id"
          :id="'inlineRadio2' + name + id"
          :key="id + '_yes'"
          :value="value"
          :checked="value === true"
          @click="$emit('auswahl', true)"
        />
        <label class="form-check-label" :for="'inlineRadio2' + name + id"
          >ja</label
        >
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "MainRadio",
  props: {
    value: Boolean,
    id: Number,
    name: String,
  },
};
</script>

<style scoped>
</style>
