<template>
  <div class="col-12" :id="id">
    <div class="card mb-3">
      <div v-if="header" class="card-header">
        {{ header }}
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ subtitle }}</h6>
        <p class="card-text" v-if="text">{{ text }}</p>

        <slot></slot>

        <div class="card-footer text-muted" v-if="footer">
          {{ footer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainCard",
  components: {},
  props: {
    id: String,
    header: String,
    title: String,
    subtitle: String,
    text: String,
    footer: String,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
